import { Form } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";

import {
    conferencesHomeLoaded,
    editConference,
    loadConferences,
} from "../../../domains/conferences/actions";
import ConferenceForm from "../../common/conferences/ConferenceForm";
import Base from "../Base";

const WrappedConferenceFrom = Form.create()(ConferenceForm);

const EditConferencePage = ({
    activeDepartments,
    editConference,
    loadConferences,
    redirectToConferencesHomePage,
    conferenceLoading,
    conferencesHomeLoaded,
    match,
    conferences,
}) => {
    useEffect(() => {
        conferencesHomeLoaded();
        loadConferences();
    }, []);

    const conferenceId = parseInt(match.params.id, 10);
    const conference =
        conferences &&
        match &&
        match.params &&
        match.params.id &&
        conferences.find(conference => conference.id === conferenceId);
    return (
        <Base
            breadcrumb={[
                { label: "Accueil", to: "/" },
                { label: "Conférences", to: "/conferences" },
                { label: "Modifier une conférence", to: `/conferences/edit/${conferenceId}` },
            ]}
        >
            <div className="center-container selector-form-container">
                {activeDepartments && (
                    <WrappedConferenceFrom
                        conferenceLoading={conferenceLoading}
                        editConference={editConference}
                        activeDepartments={activeDepartments}
                        conference={conference}
                    />
                )}
                {redirectToConferencesHomePage && <Redirect to={"/conferences"} />}
            </div>
        </Base>
    );
};

export default connect(
    state => ({
        redirectToConferencesHomePage: state.conferences.redirectToConferencesHomePage,
        conferenceLoading: state.conferences.conferenceLoading,
        activeDepartments: state.stands.activeDepartments,
        conferences: state.conferences.conferences,
    }),
    {
        editConference,
        conferencesHomeLoaded,
        loadConferences,
    }
)(withRouter(EditConferencePage));
