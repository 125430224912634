import React, { useEffect, useState } from "react";
import { Button, Empty } from "antd";
import { connect } from "react-redux";

import { Loader } from "@mafoire/components";

import { loadConferences } from "../../../domains/conferences/actions";
import { loadActiveDepartments } from "../../../domains/stands/actions";
import { loadAllVisitors } from "../../../domains/visitors/actions";
import Base from "../Base";
import ConferencesVisitorsList from "../../common/conferences/ConferencesVisitorsList";

const Conferences = ({
    loadConferences,
    loadAllVisitors,
    loadActiveDepartments,
    conferences,
    visitors,
    departments,
}) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            if (!conferences || !conferences.length) {
                await loadConferences();
            }
            if (!visitors || !visitors.length) {
                await loadAllVisitors();
            }
            if (!departments || !departments.length) {
                await loadActiveDepartments();
            }
        };
        init().then(() => {
            setIsLoading(false);
        });
    }, []);

    return (
        <Base
            pageTitle="Accueil / Conférences"
            backActionLink={"/"}
            breadcrumb={[
                { label: "Accueil", to: "/" },
                { label: "Conférences", to: "/conferences" },
                { label: "Inscriptions", to: "/conferences/visitors" },
            ]}
        >
            <div className="page-container conferences-visitors center-container">
                <div className="conferences-visitors-list">
                    {isLoading ? (
                        <Loader fullPage />
                    ) : (
                        <ConferencesVisitorsList
                            conferences={conferences}
                            visitors={visitors}
                            departments={departments}
                        />
                    )}
                </div>
            </div>
        </Base>
    );
};

export default connect(
    state => ({
        visitors: state.visitors.visitors,
        conferences: state.conferences.conferences,
        departments: state.stands.activeDepartments,
    }),
    {
        loadConferences,
        loadAllVisitors,
        loadActiveDepartments,
    }
)(Conferences);
