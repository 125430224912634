import { Button, Empty, Icon, Popconfirm, Table } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { chain, isNil } from "lodash";

import { Loader } from "@mafoire/components";

import "./ConferencesList.css";

const ConferencesList = ({ conferences, deleteConference, departments, conferenceLoading }) => {
    const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]);
    const departmentsHavingConferences =
        conferences &&
        departments &&
        chain(conferences)
            .map(c => departments.find(d => d.id === c.department_id))
            .uniq()
            .compact()
            .value();

    const columns = [
        {
            title: "Actions",
            dataIndex: "id",
            key: "actions",
            width: 100,
            render: (text, conference) => {
                return (
                    <div className="conference-list-actions">
                        {
                            <Link
                                to={{
                                    pathname: `/conferences/edit/${conference.id}`,
                                    state: { conference: conference },
                                }}
                            >
                                <Button title="Modifier" icon="edit" />
                            </Link>
                        }
                        <Popconfirm
                            title="Êtes-vous sûr de vouloir supprimer cette conférence ?"
                            onConfirm={() => deleteConference(conference.id)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button title="Supprimer" icon="delete" />
                            {conferenceLoading && conference.isLoading && (
                                <Icon className="delete-conference-loading-icon" type="loading" />
                            )}
                        </Popconfirm>
                    </div>
                );
            },
        },
        {
            title: "Département",
            key: "department_id",
            dataIndex: "department_id",
            filters:
                departmentsHavingConferences &&
                departmentsHavingConferences.length > 0 &&
                departmentsHavingConferences.map(departmentHavingConference => ({
                    text: departmentHavingConference.name,
                    value: departmentHavingConference.id,
                })),

            render: department_id => {
                const department =
                    departments && departments.find(dept => dept.id === department_id);
                return (
                    <div>
                        {department && department.name} ({department && department.code})
                    </div>
                );
            },
            filteredValue: selectedDepartmentIds,
            onFilter: (departmentId, conference) => {
                return conference.department_id === departmentId;
            },
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            className: "title",
            width: 200,
        },
        {
            title: "Auteur",
            dataIndex: "author",
            key: "author",
            className: "author",
            width: 150,
        },
        {
            title: "Couverture",
            dataIndex: "image_url",
            key: "image_url",
            className: "conference-image",
            render: image_url => (
                <div className="conference-container cover">
                    {image_url && <img src={image_url} alt="" />}
                </div>
            ),
        },
        {
            title: "Date de début",
            dataIndex: "start_date",
            key: "start_date",
            className: "date",
            render: date => <div className="conference-container cover">{date}</div>,
        },
        {
            title: "Date de fin",
            dataIndex: "end_date",
            key: "end_date",
            className: "date",
            render: date => <div className="conference-container cover">{date}</div>,
        },
        {
            title: "Payant",
            dataIndex: "is_paid",
            key: "is_paid",
            render: record => {
                return record ? "Oui" : "Non";
            },
        },
    ];

    return (
        <div>
            {!conferences ? (
                <Loader fullPage />
            ) : conferences.length === 0 ? (
                <Empty description="Vous n'avez pas encore saisi de conférences" />
            ) : (
                <Table
                    className="conferences-table"
                    columns={columns}
                    dataSource={conferences}
                    rowKey={conference => conference.id}
                    onChange={(pagination, filters, sorter, extra) => {
                        setSelectedDepartmentIds(filters.department_id || []);
                    }}
                />
            )}
        </div>
    );
};

export default ConferencesList;
