import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { Loader } from "@mafoire/components";

import {
    conferencesHomeLoaded,
    deleteConference,
    loadConferences,
} from "../../../domains/conferences/actions";
import ConferencesList from "../../common/conferences/ConferencesList";
import Base from "../Base";

const Conferences = ({
    activeDepartments,
    loadConferences,
    deleteConference,
    conferences,
    conferencesHomeLoaded,
    conferenceLoading,
}) => {
    useEffect(() => {
        loadConferences();
        conferencesHomeLoaded();
    }, []);

    return (
        <Base
            pageTitle="Accueil / Conférences"
            backActionLink={"/"}
            breadcrumb={[
                { label: "Accueil", to: "/" },
                { label: "Conférences", to: "/conferences" },
            ]}
        >
            <div className="page-container conferences center-container">
                <div className="page-header">
                    <div className="conferences-add-button">
                        <NavLink to="/conferences/add">
                            <Button type="primary">Ajouter une conférence</Button>
                        </NavLink>
                    </div>
                    <div className="conferences-view-visitors-button">
                        <NavLink to="/conferences/visitors">
                            <Button type="primary">Consulter les inscriptions</Button>
                        </NavLink>
                    </div>
                </div>
                <div className="conferences-list">
                    {!conferences ? (
                        <Loader fullPage />
                    ) : conferences.length === 0 ? (
                        <Empty description="Vous n'avez pas encore saisi de conférences" />
                    ) : (
                        <ConferencesList
                            conferences={conferences}
                            departments={activeDepartments}
                            deleteConference={deleteConference}
                            conferenceLoading={conferenceLoading}
                        />
                    )}
                </div>
            </div>
        </Base>
    );
};

export default connect(
    state => ({
        conferences: state.conferences.conferences,
        activeDepartments: state.stands.activeDepartments,
        conferenceLoading: state.conferences.conferenceLoading,
    }),
    {
        loadConferences,
        deleteConference,
        conferencesHomeLoaded,
    }
)(Conferences);
