import {
    ADD_CONFERENCE_REQUEST,
    ADD_CONFERENCE_SUCCESS,
    CONFERENCES_HOME_LOADED,
    DELETE_CONFERENCE_REQUEST,
    DELETE_CONFERENCE_SUCCESS,
    EDIT_CONFERENCE_REQUEST,
    EDIT_CONFERENCE_SUCCESS,
    LOAD_CONFERENCES_REQUEST,
    LOAD_CONFERENCES_SUCCESS,
} from "./actionTypes";

const defaultState = {
    conferences: [],
    filteredConferences: [],
    filters: {},
    conferenceBeingDeleted: null,
    conferenceLoading: false,
    conferencesLoading: false,
    redirectToConferencesHomePage: false,
    conference: null,
    isSaved: false,
};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOAD_CONFERENCES_REQUEST: {
            return {
                ...state,
                conferencesLoading: true,
            };
        }

        case LOAD_CONFERENCES_SUCCESS: {
            return {
                ...state,
                conferences: payload,
                conferencesLoading: false,
            };
        }

        case ADD_CONFERENCE_REQUEST:
            return {
                ...state,
                conferenceLoading: true,
                isSaved: false,
                redirectToConferencesHomePage: false,
            };

        case ADD_CONFERENCE_SUCCESS: {
            const conferences = [{ ...payload, isSaved: true }, ...state.conferences];

            return {
                ...state,
                conferences,
                conferenceLoading: false,
                isSaved: true,
                redirectToConferencesHomePage: true,
            };
        }

        case EDIT_CONFERENCE_REQUEST: {
            return {
                ...state,
                conferenceLoading: true,
                redirectToConferencesHomePage: false,
            };
        }

        case EDIT_CONFERENCE_SUCCESS: {
            const conferences = state.conferences.map(conference =>
                conference.id === payload.id ? { ...payload, isSaved: true } : conference
            );
            return {
                ...state,
                conferences,
                conferenceLoading: false,
                redirectToConferencesHomePage: true,
            };
        }

        case DELETE_CONFERENCE_REQUEST: {
            const conferences = [...state.conferences];
            const conferenceIndex = conferences.findIndex(c => c.id === payload);
            conferences[conferenceIndex].isLoading = true;
            return {
                ...state,
                conferenceBeingDeleted: payload,
                conferenceLoading: true,
                conferences,
                isSaved: false,
            };
        }

        case DELETE_CONFERENCE_SUCCESS: {
            const conferences = state.conferences.filter(c => c.id !== payload);
            return {
                ...state,
                conferenceLoading: false,
                conferences,
                isSaved: true,
            };
        }

        case CONFERENCES_HOME_LOADED: {
            return {
                ...state,
                redirectToConferencesHomePage: false,
            };
        }

        default:
    }
    return state;
};
