export const LOAD_CONFERENCES_REQUEST = "CONFERENCES/LOAD_CONFERENCES_REQUEST";
export const LOAD_CONFERENCES_SUCCESS = "CONFERENCES/LOAD_CONFERENCES_SUCCESS";

export const ADD_CONFERENCE_REQUEST = "CONFERENCES/ADD_CONFERENCE_REQUEST";
export const ADD_CONFERENCE_SUCCESS = "CONFERENCES/ADD_CONFERENCE_SUCCESS";

export const DELETE_CONFERENCE_REQUEST = "CONFERENCES/DELETE_CONFERENCE_REQUEST";
export const DELETE_CONFERENCE_SUCCESS = "CONFERENCES/DELETE_CONFERENCE_SUCCESS";

export const EDIT_CONFERENCE_REQUEST = "CONFERENCES/EDIT_CONFERENCE_REQUEST";
export const EDIT_CONFERENCE_SUCCESS = "CONFERENCES/EDIT_CONFERENCE_SUCCESS";

export const CONFERENCES_HOME_LOADED = "CONFERENCES/CONFERENCES_HOME_LOADED";
