import { Button, Empty, Icon, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";

import "./ConferencesVisitorsList.css";

const ConferencesVisitorsList = ({ conferences, visitors, departments }) => {
    const [conferenceVisitorArray, setConferenceVisitorArray] = useState([]);
    const [isExportLoading, setIsExportLoading] = useState(false);

    const mapToConferenceVisitor = useCallback((conference, visitor, departments) => {
        const department = departments.find(d => d.id === conference.department_id);
        return {
            id: `${visitor.id} ${conference.id}`,
            fullName: `${visitor.firstname} ${visitor.lastname}`,
            conferenceTitle: conference.title,
            departmentName: department ? department.name : "",
            email: visitor.email,
            telephone: visitor.telephone,
        };
    }, []);

    useEffect(() => {
        setConferenceVisitorArray(
            visitors
                .filter(v => v.conferences.length > 0)
                .flatMap(v => v.conferences.map(c => mapToConferenceVisitor(c, v, departments)))
        );
    }, [conferences, visitors, departments]);

    const columns = [
        {
            title: "Titre de la Conférence",
            key: "conferenceTitle",
            dataIndex: "conferenceTitle",
        },
        {
            title: "Nom du salon",
            key: "departmentName",
            dataIndex: "departmentName",
        },
        {
            title: "Nom",
            dataIndex: "fullName",
            key: "fullName",
            className: "fullName",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            className: "email",
        },
        {
            title: "Téléphone",
            dataIndex: "telephone",
            key: "telephone",
            className: "telephone",
        },
    ];

    return conferenceVisitorArray.length === 0 ? (
        <Empty description="Il n'y pas encore eu d'inscriptions" />
    ) : (
        <div className="conferences-visitors-list">
            <div className="conferences-visitors-actions">
                <Button
                    type="primary"
                    disabled={isExportLoading}
                    onClick={() => {
                        setIsExportLoading(true);
                        const csvExporter = new ExportToCsv({
                            fieldSeparator: ",",
                            quoteStrings: '"',
                            decimalSeparator: ".",
                            showLabels: true,
                            showTitle: false,
                            title: "Liste d'inscriptions",
                            useTextFile: false,
                            useBom: true,
                            useKeysAsHeaders: true,
                            filename: `Liste d'inscriptions_${moment().format("DD-MM-YYYY")}`,
                        });
                        csvExporter.generateCsv(
                            conferenceVisitorArray.map(cv => {
                                const { id, ...visibleFields } = cv;
                                return visibleFields;
                            })
                        );
                        setIsExportLoading(false);
                    }}
                >
                    {isExportLoading ? "Export en cours.." : "Exporter la liste d'inscriptions"}
                    {isExportLoading && <Icon type="loading" />}
                </Button>
            </div>
            <Table
                className="conferences-visitors-table"
                columns={columns}
                dataSource={conferenceVisitorArray}
                rowKey={visitorsHavingConferences => visitorsHavingConferences.id}
            />
        </div>
    );
};

export default ConferencesVisitorsList;
