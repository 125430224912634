import { Button, Form, Icon, Select } from "antd";
import React, { useCallback, useState } from "react";
import "./VisitorForm.css";

const FormItem = Form.Item;
const Option = Select.Option;

const VisitorForm = ({ visitor, form, privateDepartments, onSubmit }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            form.validateFields((err, values) => {
                if (err) return;
                setIsLoading(true);
                onSubmit({
                    ...visitor,
                    private_departments: values.privateDepartments,
                }).then(() => setIsLoading(false));
            });
        },
        [form, visitor]
    );

    const { getFieldDecorator } = form;

    const createOption = useCallback(
        option => (
            <Option key={option.id} value={option.id} title={option.name}>
                {option.name}
            </Option>
        ),
        []
    );

    return (
        <Form className="visitor-form" onSubmit={handleSubmit}>
            <FormItem label="Départements Privés">
                {getFieldDecorator("privateDepartments", {
                    rules: [{ required: false }],
                    initialValue:
                        visitor && visitor.private_departments
                            ? visitor.private_departments.map(department => department.id)
                            : undefined,
                })(
                    <Select
                        mode="multiple"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {privateDepartments.map(createOption)}
                    </Select>
                )}
            </FormItem>
            <FormItem className="actions">
                <Button type="primary" htmlType="submit">
                    Enregistrer
                    {isLoading && <Icon type="loading" />}
                </Button>
            </FormItem>
        </Form>
    );
};

export default Form.create()(VisitorForm);
