import { http } from "../../helpers/http";
import {
    LOAD_CONFERENCES_REQUEST,
    LOAD_CONFERENCES_SUCCESS,
    ADD_CONFERENCE_REQUEST,
    ADD_CONFERENCE_SUCCESS,
    DELETE_CONFERENCE_REQUEST,
    DELETE_CONFERENCE_SUCCESS,
    CONFERENCES_HOME_LOADED,
    EDIT_CONFERENCE_REQUEST,
    EDIT_CONFERENCE_SUCCESS,
} from "./actionTypes";
import { getProfile } from "../../helpers/local-storage";

export const loadConferences = () => dispatch => {
    const profile = getProfile();
    if (profile) {
        dispatch({
            type: LOAD_CONFERENCES_REQUEST,
            payload: {},
        });

        const options = {
            method: "get",
            url: "/api/conferences",
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        };

        http(options).then(results => {
            dispatch({
                type: LOAD_CONFERENCES_SUCCESS,
                payload: results,
            });
        });
    }
};

export const addConference = payload => dispatch => {
    const profile = getProfile();
    if (profile) {
        dispatch({
            type: ADD_CONFERENCE_REQUEST,
            payload,
        });

        const formData = new FormData();
        Object.entries(payload).forEach(([k, v]) => {
            if (v !== undefined && v !== null) {
                formData.append(k, v);
            }
        });

        const options = {
            method: "post",
            url: `/api/conferences`,
            payload: formData,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        };

        http(options).then(results => {
            dispatch({
                type: ADD_CONFERENCE_SUCCESS,
                payload: results,
            });
        });
    }
};

export const editConference = payload => dispatch => {
    const profile = getProfile();
    if (profile) {
        dispatch({
            type: EDIT_CONFERENCE_REQUEST,
        });

        const formData = new FormData();
        Object.entries(payload).forEach(([k, v]) => {
            if (v !== undefined && v !== null) {
                formData.append(k, v);
            }
        });

        const options = {
            method: "post",
            url: `/api/conferences/${payload.conferenceId}`,
            payload: formData,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        };

        http(options).then(results => {
            dispatch({
                type: EDIT_CONFERENCE_SUCCESS,
                payload: results,
            });
        });
    }
};

export const deleteConference = conferenceId => dispatch => {
    const profile = getProfile();
    if (profile) {
        dispatch({
            type: DELETE_CONFERENCE_REQUEST,
            payload: conferenceId,
        });

        const options = {
            method: "delete",
            url: `/api/conferences/${conferenceId}`,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        };

        http(options).then(results => {
            dispatch({
                type: DELETE_CONFERENCE_SUCCESS,
                payload: conferenceId,
            });
        });
    }
};

export const conferencesHomeLoaded = () => dispatch => {
    dispatch({
        type: CONFERENCES_HOME_LOADED,
    });
};
