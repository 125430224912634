import { FileImageOutlined } from "@ant-design/icons";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Icon,
    Input,
    message,
    Select,
    Typography,
    Upload,
} from "antd";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Loader } from "@mafoire/components";

import { checkFile } from "../../../helpers/functions";

import "./ConferenceForm.css";

const FormItem = Form.Item;
const { Title } = Typography;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const maxFileSize = window.process.env.APP_FILE_UPLOAD_SIZE_LIMIT;

const ConferenceForm = ({
    form,
    activeDepartments,
    addConference,
    editConference,
    conference,
    conferenceLoading,
    resetConferences,
}) => {
    const { getFieldDecorator, validateFields } = form;

    const [imageFile, setImageFile] = useState(null);
    const [imageFileLocalUrl, setImageFileLocalUrl] = useState(null);

    const [isPaid, setIsPaid] = useState(conference && conference.is_paid ? 1 : 0);

    const [startDate, setStartDate] = useState(conference && conference.start_date);
    const [endDate, setEndDate] = useState(conference && conference.end_date);

    const storeImageFile = useCallback(
        file => {
            if (!imageFile) {
                computeFilePreview(file, setImageFileLocalUrl);
            }
            if (imageFile && imageFile !== file) {
                URL.revokeObjectURL(imageFileLocalUrl);
                computeFilePreview(file, setImageFileLocalUrl);
            }
            setImageFile(file);
        },
        [imageFile, imageFileLocalUrl]
    );

    const computeFilePreview = useCallback((file, setStateCallback) => {
        setStateCallback(URL.createObjectURL(file));
    }, []);

    const createOption = useCallback(
        option => (
            <Option key={option.id} value={option.id} title={option.name}>
                {option.name}
            </Option>
        ),
        []
    );

    const handleSubmit = e => {
        e.preventDefault();
        validateFields((err, values) => {
            if (err) {
                return;
            }
            if (!startDate || !endDate) {
                message.error("Veuillez selectionnez la date de début ainsi que la date de fin.");
                return;
            }
            const data = {
                ...values,
                is_paid: isPaid ? 1 : 0,
                file: imageFile,
                start_date: startDate,
                end_date: endDate,
            };
            if (conference) {
                const handleDisplayAndAlert = async () => {
                    await editConference({
                        ...data,
                        conferenceId: conference.id,
                    });
                    // form.resetFields();
                };
                handleDisplayAndAlert();
                resetConferences();
            } else {
                const handleDisplayAndAlert = async () => {
                    await addConference(data);
                    // form.resetFields();
                };
                handleDisplayAndAlert();
                resetConferences();
            }
        });
    };

    return (
        <div className="center-container conference-form-container">
            <Form className="conference-form" onSubmit={handleSubmit}>
                <div className="conference-metadata-container">
                    <Title level={4}> Infos </Title>
                    <FormItem label="Titre">
                        {getFieldDecorator("title", {
                            initialValue: conference ? conference.title : "",
                            rules: [
                                {
                                    required: true,
                                    message: "Veuillez renseigner le titre",
                                },
                            ],
                        })(<Input type="text" autoFocus />)}
                    </FormItem>
                    <FormItem label="Description">
                        {getFieldDecorator("description", {
                            initialValue: conference ? conference.description : "",
                        })(<Input type="text" />)}
                    </FormItem>

                    <FormItem label="Auteur">
                        {getFieldDecorator("author", {
                            initialValue: conference ? conference.author : "",
                        })(<Input type="text" />)}
                    </FormItem>

                    <div className="conference-image-container">
                        <div className="conference-image-label-container">
                            <div className="conference-image-label">
                                <span>Image</span>
                            </div>
                            <div className="conference-image">
                                {imageFile && imageFileLocalUrl ? (
                                    <img src={imageFileLocalUrl} alt={imageFile.name} />
                                ) : conference && conference.image_url ? (
                                    <img src={conference.image_url} alt="" />
                                ) : (
                                    <div>
                                        <FileImageOutlined
                                            style={{
                                                fontSize: "4rem",
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="conference-image-upload-button-container">
                            <Upload
                                name="file"
                                beforeUpload={image => {
                                    if (checkFile(image, message, ["image"], maxFileSize))
                                        storeImageFile(image);
                                    return false;
                                }}
                                disabled={conferenceLoading}
                                showUploadList={false}
                                customRequest={() => false}
                            >
                                <Button className="upload-avatar-button" type="primary">
                                    <Icon className="upload-icon" type="upload" />
                                    <span className="label">Télécharger un fichier</span>
                                </Button>
                            </Upload>
                        </div>
                    </div>

                    <FormItem label="Département">
                        {getFieldDecorator("department_id", {
                            rules: [{ required: true }],
                            initialValue: conference ? conference.department_id : null,
                        })(
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.title.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                            >
                                {activeDepartments.map(createOption)}
                            </Select>
                        )}
                    </FormItem>

                    <FormItem label="Dates de début et fin">
                        <RangePicker
                            defaultValue={
                                startDate && endDate ? [moment(startDate), moment(endDate)] : []
                            }
                            showTime
                            onChange={(date, dateString) => {
                                if (dateString[0]) {
                                    setStartDate(dateString[0]);
                                }
                                if (dateString[1]) {
                                    setEndDate(dateString[1]);
                                }
                            }}
                        />
                    </FormItem>

                    <FormItem label="Catégorie">
                        {getFieldDecorator("category", {
                            initialValue: conference ? conference.category : "",
                        })(<Input type="text" />)}
                    </FormItem>

                    <Title level={4}> Replay </Title>
                    <FormItem label="ID Youtube">
                        {getFieldDecorator("youtube_replay_id", {
                            initialValue: conference ? conference.youtube_replay_id : "",
                        })(<Input type="text" />)}
                    </FormItem>
                    <FormItem label="ID Dailymotion">
                        {getFieldDecorator("dailymotion_replay_id", {
                            initialValue: conference ? conference.dailymotion_replay_id : "",
                        })(<Input type="text" />)}
                    </FormItem>
                    <FormItem label="ID Vimeo">
                        {getFieldDecorator("vimeo_replay_id", {
                            initialValue: conference ? conference.vimeo_replay_id : "",
                        })(<Input type="text" />)}
                    </FormItem>
                </div>
                <div className="conference-payment-container">
                    <Title level={4}> Zoom </Title>

                    <FormItem label="Lien Zoom">
                        {getFieldDecorator("zoom_link", {
                            initialValue: conference ? conference.zoom_link : "",
                        })(<Input type="text" />)}
                    </FormItem>

                    <FormItem label="Id Zoom">
                        {getFieldDecorator("zoom_id", {
                            initialValue: conference ? conference.zoom_id : "",
                        })(<Input type="text" />)}
                    </FormItem>

                    <FormItem label="Mot de passe Zoom">
                        {getFieldDecorator("zoom_password", {
                            initialValue: conference ? conference.zoom_password : "",
                        })(<Input type="text" />)}
                    </FormItem>

                    <Divider />
                    <Title level={4}> Paiement </Title>

                    <FormItem label="Payant" className="conference-payment-checkbox-container">
                        {getFieldDecorator("is_paid", {
                            initialValue: isPaid,
                        })(
                            <Checkbox
                                checked={isPaid}
                                onChange={event => {
                                    setIsPaid(event.target.checked);
                                }}
                            />
                        )}
                    </FormItem>

                    <FormItem label="Lien Paypal">
                        {getFieldDecorator("paypal_link", {
                            initialValue: conference ? conference.paypal_link : "",
                        })(<Input type="text" disabled={!isPaid} />)}
                    </FormItem>
                </div>
                <FormItem className="actions">
                    <Button type="secondary">
                        <Link to="/conferences" onClick={resetConferences}>
                            Annuler
                        </Link>
                    </Button>
                    <Button className="validation-button" type="primary" htmlType="submit">
                        Enregistrer
                        {conferenceLoading && <Loader />}
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
};

export default Form.create()(ConferenceForm);
